import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import axios from 'axios'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        isLoggedIn: !!localStorage.getItem("token"),
        userType: localStorage.getItem('userType'),
        userData: {},
        email: '',
        menus: [],
        initial: "",
        appLoading: false
    },
    mutations: {
        loginUser(state, payload) {
            localStorage.setItem("token", payload);
            console.log(payload)
            state.isLoggedIn = true
            if (state.userType == 'Admin') router.push('/')
            else router.push('/')
        },
        logoutUser(state) {
            axios({
                method: "GET",
                url: "/user/logout",
                headers: {
                    "x-auth-token": localStorage.getItem("token")
                }
            }).then(response => {
                if (response.data) {
                    state.userType = null
                    state.isLoggedIn = false
                    state.userData = {}
                    state.initial = "";
                    localStorage.removeItem("userType");
                    localStorage.removeItem("token");
                    router.push('/')
                }
            })
        },
        menu(state, item) {
            state.menus = item;
            if (item[0]._id !== "60ed5bd072b4ca543c57424d") {
                state.initial = item[0].subMenu[0].route;
            } else {
                state.initial = "/Admins";
            }
        },
        userData(state, payload) {
            state.userData = payload
            state.email = state.userData.email
        },
        userType(state, payload) {
            localStorage.setItem("userType", payload);
            state.userType = payload
        },
        appLoading(state, payload) {
            state.appLoading = payload
        },
        sessionOut(state) {
            localStorage.removeItem("token");
            state.isLoggedIn = false
        }
    }

})