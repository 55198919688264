import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import axios from "axios";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/appSidebar",
      props: true,
      component: () => import("./components/Common/appSidebar"),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: "/appDashboard",
          name: "dashboard",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Common/appDashboard"),
        },
        {
          path: "/home",
          name: "home",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Home/home"),
        },
        {
          path: "/category",
          name: "category",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Category/category"),
        },
        {
          path: "/subCategory",
          name: "subCategory",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/SubCategory/subCategory"),
        },
        {
          path: "/product",
          name: "product",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Product/product"),
        },
        {
          path: "/productDetails",
          name: "product details",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Product/productDetails"),
        },
        {
          path: "/contactCategory",
          name: "contact category",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Contact/contactCategory"),
        },
        {
          path: "/contactList",
          name: "contact list",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Contact/contactList"),
        },
        {
          path: "/contactDetails",
          name: "contact details",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Contact/contactDetails"),
        },
        {
          path: "/about",
          name: "about",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/About/about"),
        },
        {
          path: "/socialMedias",
          name: "socialMedias",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/SocialMedia/socialMedias"),
        },
        {
          path: "/services",
          name: "services",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Services/services"),
        },
        {
          path: "/serviceList",
          name: "serviceList",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Services/serviceList"),
        },
        {
          path: "/quote",
          name: "Quote",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Quote/quote"),
        },
        {
          path: "/quoteDetails",
          name: "QuoteDetails",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Quote/quoteDetails"),
        },

        {
          path: "/InternBatch",
          name: "InternBatch",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Intern/BatchList/batchList"),
        },

        {
          path: "/InternProjects",
          name: "InternProjects",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Intern/Projects/project"),
          },
          {
            path: "/Interns",
            name: "Interns",
            meta: {
              requireAuth: true,
            },
            component: () => import("./components/Intern/Students/students"),
          },
          {
            path: "/InternsApproval",
            name: "InternsApproval",
            meta: {
              requireAuth: true,
            },
            component: () => import("./components/Intern/Students/approveStudents"),
          },
      ],
    },
    {
      path: "/",
      name: "login",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/login"),
    },
    // {
    //     path: '/',
    //     props: true,
    //     name: 'AdminDashboard',
    //     component: () =>
    //         import ('./components/Common/appDashboard'),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    {
      path: "/ServerError",
      name: "ServerError",
      props: true,
      component: () => import("./components/Common/500"),
    },
    {
      path: "*",
      name: "404PageNotFound",
      props: true,
      meta: {
        requiresAuth: false,
      },
      component: () => import("./components/Common/404"),
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((route) => route.meta.requireAuth == true) &&
    store.state.isLoggedIn == false
  ) {
    console.log(from);
    next({ name: "login", params: { lastPage: from } });
    return;
  }
  if (store.state.isLoggedIn == true) {
    axios({
      method: "POST",
      url: "/validate/token",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (!response.data.status) {
          store.commit("sessionOut", true);
          return;
        }
      })
      .catch((err) => {
        var msg = err;
        console.log(msg);
      });
  }
  if (to.name == "login" && store.state.isLoggedIn == true) {
    next({ name: "dashboard" });
    return;
  }
  next();
});
export default router;
